//
// user-variables.scss
// Use this to override Bootstrap and Cartzilla variables
//

// Grid containers
//
// Modifying default Bootstrap's $container-max-widths map (max-width of .container)
$container-max-widths: (
        sm: 100%,
        md: 100%,
        lg: 100%,
        xl: 1400px
) !default;

$info: #41a5df !default;
$success: #04a53a !default;
$warning: #e77c35 !default;
$danger: #d31e2d !default;

$border-radius: 0.15rem !default;
$border-radius-lg: 0.2rem !default;
$border-radius-sm: 0.1rem !default;
