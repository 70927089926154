//
// user.scss
// Use this to write your custom SCSS
//

$header-height-desktop: 74px !default;
$header-height-mobile: 57.75px !default;

$header-with-topbar-height-desktop: 111px !default;
$header-with-topbar-height-mobile: 94.75px !default;

:root {
  --header-height-desktop: #{$header-height-desktop};
  --header-height-mobile: #{$header-height-mobile};
  --header-with-topbar-height-desktop: #{$header-with-topbar-height-desktop};
  --header-with-topbar-height-mobile: #{$header-with-topbar-height-mobile};
}

body {
  overflow-x: hidden;
}

//.nav-link:not(.dropdown-toggle).active {
//  pointer-events: all !important;
//}

.btn-lg, .btn-group-lg > .btn {
  @include media-breakpoint-down(md) {
    padding: 0.875rem;
    &, strong {
      font-size: 0.875rem;
    }
  }
  @include media-breakpoint-down(sm) {
    padding: 0.75rem;
    &, strong {
      font-size: 0.75rem;
    }
  }
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.dropdown-menu-right {
  right: auto;
  bottom: auto;
}

.header-landing {
  height: $header-height-mobile;

  @include media-breakpoint-up(lg) {
    height: $header-height-desktop;
  }

  .navbar {
    padding-top: 0;
    padding-bottom: 0;

    > .container {
      display: flex;
      flex-wrap: nowrap;
    }
  }

  .navbar-link.dropdown-toggle {
    &::after {
      display: none;
    }
  }

  .navbar-brand {
    > img {
      max-height: 2.5rem;
      min-width: 8rem;

      @include media-breakpoint-down(lg) {
        margin-right: 0;
      }

      @include media-breakpoint-up(lg) {
        max-height: 3rem;
        min-width: 14rem;
      }
    }
  }

  .btn {
    border: 1px solid transparent;

    @include media-breakpoint-down(lg) {
      padding: 0.425rem 1rem;
      font-size: 0.775rem;
      border-radius: 0.1rem;
    }

    @include media-breakpoint-up(lg) {
      padding: 0.625rem 1.375rem;
      font-size: 0.9375rem;
      border-radius: 0.15rem;
    }

    &.dj-btn-login {
      @include media-breakpoint-down(lg) {
        margin-right: 0;
      }

      @include media-breakpoint-up(lg) {
        margin-right: 1.25rem;
      }
    }
  }
}

.dj-company-topbar {
  display: none;
  background-color: black !important;

  .topbar-text {
    text-overflow: ellipsis;

    > i {
      color: white;
    }
  }
}


.dj-content-overlay {
  z-index: 20;
  position: relative;
}

.dj-bg-cover {
  background-size: cover;
  overflow: hidden;
}

.dj-bg-overlay {
  position: relative;

  @at-root .dj-layout-dialog & {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
  }

  &::after {
    z-index: 1;
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(black, 0.25);
  }
}

.footer {
  padding: 1.5rem 0;
  background-color: black;


  &,
  .widget-list-link {
    font-size: 0.75rem;

    @include media-breakpoint-down(md) {
      font-size: 0.7rem;
    }
  }

  .widget-list-item:not(:last-child) {
    margin-right: 1rem;
    text-align: center;
  }
}

.dj-product-card-alt {
  border: 1px solid rgba(black, 0);
  box-shadow: 1px 1px 20px rgb(0 0 0 / 8%);

  .badge {
    position: absolute;
    top: 0.75rem;
    right: auto;
    left: 0.75rem;
    z-index: 5;
  }
}

.dj-tns-category-img {
  object-fit: cover !important;
  width: 100% !important;
  height: 180px !important;
}

.dj-product {
  .js-action-add-container,
  .js-action-custom-button-label {
    display: block !important;
  }

  .js-action-qty-container,
  .js-action-custom-container,
  .js-action-custom-button-label-is-in-cart {
    display: none !important;
  }

  &.is-in-cart {
    .js-action-add-container,
    .js-action-custom-button-label {
      display: none !important;
    }

    .js-action-qty-container,
    .js-action-custom-button-label-is-in-cart {
      display: block !important;
    }
  }

  &.has-quantity-custom-selected {
    .js-action-add-container,
    .js-action-qty-container {
      display: none !important;
    }

    .js-action-custom-container {
      display: block !important;
    }
  }
}

.js-cart-rows-item {
  .js-action-custom-button-label {
    display: none !important;
  }

  .js-action-custom-button-label-is-in-cart {
    display: block !important;
  }

  .js-action-qty-container {
    width: 100%;
  }
}

.dj-btn-inside {

}

// .js-cart-rows-item.has-price-special-active {
//   background-color: rgba(yellow, 0.2);
// }

.btn-wishlist {
  display: flex;
  align-items: center;
  justify-content: center;
}

.js-action-favorite-button {
  .icon-on {
    display: none !important;
    color: $primary;
  }

  .icon-off {
    display: block !important;
    color: $dark;
  }

  &.is-active {
    .icon-on {
      display: block !important;
    }

    .icon-off {
      display: none !important;
    }
  }
}

.dj-product-card {
  .product-title {
    min-height: 48px;
  }

  &.product-card {
    .card-body-hidden {
      position: relative;
      opacity: 1;
      visibility: visible;
      margin-top: 0;
      padding-top: 0;

      /* @include media-breakpoint-up(lg) {
        position: absolute;
        margin-top: -1.25rem;
        opacity: 0;
        visibility: hidden;
      } */
    }

    &.product-card.product-list .card-body-hidden {
      top: 0;
      margin-top: 0;
      transform: none;
    }
  }
}


.dj-product--detail {
  .product-card, .card-body {
    background: transparent;
  }

  .card-body {
    padding-right: 0;
    padding-left: 0;
    font-size: 1.5rem;
  }

  .product-card:not(.card-static):hover {
    box-shadow: none !important;
  }

  .badge {
    position: static;
    top: 0;
    left: 0;
    right: auto;
  }

  .product-actions-trigger {
    flex-grow: 1;
  }
}

.steps-dark .step-item {
  &, &:hover {
    color: white;
  }
}

.offcanvas {
  backface-visibility: hidden;

  &.offcanvas-collapse {
    .offcanvas-header {
      display: flex;
    }
  }

  .offcanvas-body {
    overflow-y: auto;
  }
}

// #dj-drawer-filters-in-page .offcanvas {
//   z-index: 9999;

//   @include media-breakpoint-up(lg) {
//     visibility: visible !important;

//     &.offcanvas-collapse {
//       display: block;
//       position: static;
//       top: auto !important;
//       right: auto !important;
//       bottom: auto !important;
//       left: auto !important;
//       width: 100% !important;
//       height: auto !important;
//       transform: none !important;
//       background-color: transparent;
//     }
//   }
// }

.dj-checkout-step-body {
  @include media-breakpoint-up(lg) {
    max-height: 23rem;
    overflow-y: auto;
  }
}

.dj-form-field {
  @extend .mb-3;
}

/* .dj-auth-form {
  @include media-breakpoint-down(lg) {
    margin-bottom: 4rem;

    &__actions {
      position: fixed;
      bottom: 0;
      left: 0;
      width: 100%;
      padding: 1rem;
      background-color: white;
      margin: 0 auto !important;
    }
  }
} */

.dj-checkout-step-body {
  max-height: calc(100vh - 300px);
  overflow-y: auto;
}

.dj-checkout__actions {
  @include media-breakpoint-down(md) {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 1rem;
    background: white;
  }
}

.dj-toolbar {
  .handheld-toolbar-item {
    width: 100%;
    padding: 0.25rem;
  }
}

.dj-field-phone-edit {
  .btn {
    margin-top: 0.85rem;
    margin-left: 1rem;
    padding-right: 0.75rem;
    padding-left: 0.75rem;
  }
}

.dj-profile form {
  @include media-breakpoint-down(md) {
    .btn {
      font-size: 0.80rem;
    }
  }
}

.bg-transparent {
  background-color: transparent !important;
}


.table > :not(:first-child) {
  border-top: 1px solid $gray-400 !important;
}


.widget-categories .accordion-button::after {
  transform: rotate(270deg);
}

.dj-category-related {
  @include media-breakpoint-down(md) {
    max-width: calc(100vh - 3rem);
    overflow-x: auto;
    flex-wrap: nowrap;
    margin: 0 -1.5rem;
    padding: 0 1rem;

    li > * {
      white-space: pre;
    }
  }
}


.dj-u-truncate {
  $-truncate-rows: 3;
  display: -webkit-box !important;
  -webkit-line-clamp: $-truncate-rows !important;
  -webkit-box-orient: vertical !important;
  text-overflow: ellipsis !important;
  overflow: hidden !important;
}

.dj-cart__shipping-price {
  font-weight: 500;
}

.dj-cart-count {
  display: flex;
  align-items: center;
  padding: 0 0.5rem;
  width: auto;
  border-radius: 0.5rem;

  .navbar-tool-icon {
    order: 0;
  }

  .navbar-tool-label {
    order: 1;
    position: static;
    top: auto;
    right: auto;
    display: flex;
    width: auto;
    height: 1.25rem;
    border-radius: 0.5rem;
    min-width: 1.5rem;
    line-height: 1.25rem;
    padding: 0 0.25rem;
    margin-left: 0.25rem;
  }
}

.dj-toolbar .dj-cart-count {
  flex-direction: column;
  border-radius: 0;
  justify-content: center;
  align-items: center;
}

.swal2-container {
  z-index: 9999 !important;

  .swal2-styled {
    min-width: 80px;
  }
}


.swal2-styled.swal2-confirm {
  background-color: $primary !important;
  border: 1px solid $primary !important;
}

.swal2-styled.swal2-cancel {
  background-color: $white !important;
  color: #6e7d88 !important;
  border: 1px solid #6e7d88 !important;
}


.sk-folding-cube {
  margin: 20px auto;
  width: 40px;
  height: 40px;
  position: relative;
  -webkit-transform: rotateZ(45deg);
  transform: rotateZ(45deg);
}

.sk-folding-cube .sk-cube {
  float: left;
  width: 50%;
  height: 50%;
  position: relative;
  -webkit-transform: scale(1.1);
  -ms-transform: scale(1.1);
  transform: scale(1.1);
}

.sk-folding-cube .sk-cube:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: lighten($primary, 20%); //#d1d9e1;
  -webkit-animation: sk-foldCubeAngle 2.4s infinite linear both;
  animation: sk-foldCubeAngle 2.4s infinite linear both;
  -webkit-transform-origin: 100% 100%;
  -ms-transform-origin: 100% 100%;
  transform-origin: 100% 100%;
}

.sk-folding-cube .sk-cube2 {
  -webkit-transform: scale(1.1) rotateZ(90deg);
  transform: scale(1.1) rotateZ(90deg);
}

.sk-folding-cube .sk-cube3 {
  -webkit-transform: scale(1.1) rotateZ(180deg);
  transform: scale(1.1) rotateZ(180deg);
}

.sk-folding-cube .sk-cube4 {
  -webkit-transform: scale(1.1) rotateZ(270deg);
  transform: scale(1.1) rotateZ(270deg);
}

.sk-folding-cube .sk-cube2:before {
  -webkit-animation-delay: 0.3s;
  animation-delay: 0.3s;
}

.sk-folding-cube .sk-cube3:before {
  -webkit-animation-delay: 0.6s;
  animation-delay: 0.6s;
}

.sk-folding-cube .sk-cube4:before {
  -webkit-animation-delay: 0.9s;
  animation-delay: 0.9s;
}

@-webkit-keyframes sk-foldCubeAngle {
  0%, 10% {
    -webkit-transform: perspective(140px) rotateX(-180deg);
    transform: perspective(140px) rotateX(-180deg);
    opacity: 0;
  }
  25%, 75% {
    -webkit-transform: perspective(140px) rotateX(0deg);
    transform: perspective(140px) rotateX(0deg);
    opacity: 1;
  }
  90%, 100% {
    -webkit-transform: perspective(140px) rotateY(180deg);
    transform: perspective(140px) rotateY(180deg);
    opacity: 0;
  }
}

@keyframes sk-foldCubeAngle {
  0%, 10% {
    -webkit-transform: perspective(140px) rotateX(-180deg);
    transform: perspective(140px) rotateX(-180deg);
    opacity: 0;
  }
  25%, 75% {
    -webkit-transform: perspective(140px) rotateX(0deg);
    transform: perspective(140px) rotateX(0deg);
    opacity: 1;
  }
  90%, 100% {
    -webkit-transform: perspective(140px) rotateY(180deg);
    transform: perspective(140px) rotateY(180deg);
    opacity: 0;
  }
}

.dj-loader {
  display: none;
  align-items: center;
  justify-content: center;
  width: 100%;
  top: 0;
  left: 0;

  &.is-inline {
    z-index: 1;
    position: relative;
    height: auto;

    &::before {
      height: auto;
    }
  }

  &.is-fixed {
    z-index: 999999;
    position: fixed;
    height: 100%;

    &::before {
      height: 100vh;
    }
  }

  &__icon {
    position: absolute;
    top: calc(50% - 1em);
    left: calc(50% - 1em);
  }

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    background: rgba(white, 0.3);
    width: 100vw;
  }
}


.is-loading {
  //position: relative;

  .dj-loader {
    display: flex;
  }
}

.toast {
  position: relative;

  @include media-breakpoint-down(md) {
    padding: 0.5em;
    font-size: 0.8rem;

    &-body {
      padding: 0.35em 1em 0.35em 1.5em;
    }
  }

  .btn-close {
    position: absolute;
    color: $primary;
    background-color: white;
    background-size: 50%;
    box-shadow: 1px 1px 6px rgba(black, 0.25);
    top: -0.5rem;
    right: -1rem;
    height: 1rem;
    width: 1rem;
    font-size: 1em;
    padding: 0.2em;
    border-radius: 50%;
  }

  &-body {
    padding-left: 3.5em;

    &::before {
      position: absolute;
      top: calc(50% - 0.5em);
      left: 1em;
      width: 1em;
      height: 1em;
      display: inline-block;
      font-family: "cartzilla-icons" !important;
      font-size: 1.25em;
      speak: never;
      font-style: normal;
      font-weight: normal;
      font-variant: normal;
      text-transform: none;
      line-height: 1;

      @include media-breakpoint-down(md) {
        font-size: 1em;
      }
    }
  }

  &-danger {
    .toast-body::before {
      content: "";
    }
  }

  &-success {
    .toast-body::before {
      content: "";
    }
  }

  &-warning {
    .toast-body::before {
      content: "";
    }
  }

  &-info {
    .toast-body::before {
      content: "";
    }
  }
}

.card-img-top img {
  object-position: center;
  object-fit: scale-down;
}

.card-img-top {
  padding: 1.5em;
}


#messages {
  margin: 0 auto;
  z-index: 999999;
  max-width: 320px;
  right: 1.5rem;

  top: $header-height-mobile;

  @include media-breakpoint-up(lg) {
    top: calc(#{$header-height-desktop} - 1.5rem);
  }
}


.summary-grid {
  display: grid;
  grid-gap: 0;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));

  &--full {
    grid-template-columns: repeat(auto-fill, minmax(100%, 1fr));
  }

  &__cell {
    padding: 1rem;

    * {
      font-size: 0.9rem;
    }
  }
}

.dj-drawer--filters {
  max-width: 22rem;
  padding-bottom: 76.5px;

  .dj-drawer__footer {
    z-index: 1;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
  }

  .widget-filter-list {
    max-height: 11rem;
  }
}


.dj-filtered-by {
  @include media-breakpoint-down(md) {
    box-shadow: none !important;
    background: none !important;
    padding-left: 1rem;

    .card-body {
      margin-right: -1rem;
      margin-left: -2.25rem;
    }
  }

  .ci-close {
    width: 0.75em;
    height: 0.75em;
    font-size: 0.75em;
  }

  &__nav {
    @include media-breakpoint-down(md) {
      max-width: calc(100vh - 3rem);
      overflow-x: auto;
      flex-wrap: nowrap;
      margin: 0 -1.5rem;
      padding: 0 1rem;
      display: flex;
      flex-direction: row;
    }
  }
}


.js-app-products {
  min-height: 500px;
}


.cookiebar {
  z-index: 100000;
  position: fixed;
  right: 3rem;
  bottom: 3rem;
  max-width: 500px;
  padding: 1.5rem 1.25rem;
  font-size: 0.85rem;
  border-radius: 0.25rem;
  box-shadow: 1px 1px 30px rgb(0 0 0 / 15%);

  @include media-breakpoint-down(md) {
    left: 2rem;
    right: 2rem;
    bottom: 85px;
  }

  svg {
    margin-top: 0.25rem;
    margin-right: 1rem;
    width: 3rem;
  }

  &__inner {
    flex-wrap: nowrap;
    align-items: center;
    justify-content: space-between;
  }

  &__content {
    display: flex;
    align-items: start;
  }

  &__actions {
    margin-top: 1.25rem;
    margin-left: 2.5rem;
  }
}

.text-gray-600 {
  color: $gray-600;
}

.page-wrapper {
  position: relative;
}

@supports (padding-top: constant(safe-area-inset-top)) {
  html.is-ionic.is-ios {
    --safe-area-inset-top: constant(safe-area-inset-top);
    --safe-area-inset-bottom: constant(safe-area-inset-bottom);

    .dj-toolbar {
      padding-bottom: calc(var(--safe-area-inset-top) + var(--safe-area-inset-bottom));
    }

    .dj-checkout__actions {
      padding-bottom: calc(1rem + var(--safe-area-inset-top) + var(--safe-area-inset-bottom));
    }

    #dj-drawer-cart, #dj-drawer-filters {
      will-change: transform;
    }

    #dj-drawer-cart .offcanvas-footer {
      padding-bottom: calc(1rem + var(--safe-area-inset-top) + var(--safe-area-inset-bottom));
    }

    #dj-drawer-filters .offcanvas-footer {
      margin-bottom: calc(var(--safe-area-inset-top) + var(--safe-area-inset-bottom));
    }
  }
}

@supports (padding-top: env(safe-area-inset-top)) {
  html.is-ionic.is-ios {
    --safe-area-inset-top: env(safe-area-inset-top);
    --safe-area-inset-bottom: env(safe-area-inset-bottom);

    .dj-toolbar {
      padding-bottom: calc(var(--safe-area-inset-top) + var(--safe-area-inset-bottom));
    }

    .dj-checkout__actions {
      padding-bottom: calc(1rem + var(--safe-area-inset-top) + var(--safe-area-inset-bottom));
    }

    #dj-drawer-cart .offcanvas-footer {
      padding-bottom: calc(1rem + var(--safe-area-inset-top) + var(--safe-area-inset-bottom));
    }

    #dj-drawer-filters .offcanvas-footer {
      margin-bottom: calc(var(--safe-area-inset-top) + var(--safe-area-inset-bottom));
    }
  }
}

.has-navbar-fixed {
  .header-landing {
    z-index: 1000;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
  }

  margin-top: var(--header-height-mobile);

  @include media-breakpoint-up(lg) {
    margin-top: var(--header-height-desktop);
  }
}

.has-company-topbar {
  .dj-company-topbar {
    z-index: 1000;
    position: fixed;
    top: 0;
    left: 0;
    display: block;
    width: 100%;
  }

  &.has-navbar-fixed {
    margin-top: var(--header-with-topbar-height-mobile);

    @include media-breakpoint-up(lg) {
      margin-top: var(--header-with-topbar-height-desktop);
    }

    .header-landing {
      top: 37px;
    }
  }
}

.product-modal-heading {
  display: none;
}

.product-modal {
  .product-modal-heading {
    display: block;
  }
}

.modal-body {
  @include media-breakpoint-down('sm') {
    padding: 0.75rem;
  }
}

.modal-xl {
  max-width: 1200px !important;
  width: 100% !important;

  @include media-breakpoint-down('xl') {
    max-width: calc(100vw - 3rem) !important;
    margin: 1rem auto !important;
  }

  @include media-breakpoint-down('sm') {
    max-width: calc(100vw - 2rem) !important;
  }
}

.dj-carousel-with-controls {
  &.tns-carousel [data-controls] {
    top: calc(50% - 2rem);

    @include media-breakpoint-down('lg') {
      opacity: 1;

      &[data-controls="prev"],
      &[data-controls="next"] {
        transform: translateX(0);
      }
    }

    @include media-breakpoint-up('lg') {
      top: calc(50% - 3rem);
    }
  }

  &.dj-carousel-with-controls-no-subtitle {
    &.tns-carousel [data-controls] {
      top: calc(50% - 1rem);

      @include media-breakpoint-up('lg') {
        top: calc(50% - 2rem);
      }
    }
  }
}

.dj-hero-carousel {
  &__item {
    height: 70vh;

    @include media-breakpoint-up(md) {
      height: 700px;
    }
  }

  .tns-gallery {
    > * {
      z-index: 2;
    }

    .bg-cover {
      z-index: 1;
      background-size: cover;
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
    }
  }

  &__image {
    img {
      position: absolute;
      object-fit: cover;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      z-index: 3;
    }

    &::after {
      content: '';
      display: block;
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 3;
      background: black;
      opacity: 0.5;
    }
  }

  &__content {
    position: relative;
    z-index: 3;
  }
}

.dj-shop-hero-carousel {
  &__item {
    height: 45vh;

    @include media-breakpoint-up(md) {
      height: 400px;
    }
  }

  .tns-gallery {
    > * {
      z-index: 2;
    }

    .bg-cover {
      z-index: 1;
      background-size: cover;
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
    }
  }

  &__image {
    img {
      position: absolute;
      object-fit: cover;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      z-index: 3;
    }

    &::after {
      content: '';
      display: block;
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 3;
      background: black;
      opacity: 0.5;
    }
  }

  &__content {
    position: relative;
    z-index: 3;
  }
}

.dj-banners {
  .row > [class*='col']:first-child:only-child {
    width: 100%;
  }

  &__item {
    position: relative;
    overflow: hidden;

    &__content {
      position: relative;
      width: 100%;
      z-index: 6;
    }

    &--cover {
      &::after {
        content: '';
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 5;
        width: 100%;
        height: 100%;
        background-color: black;
        opacity: 0.45;
      }

      .dj-banners__item__content {
        width: 75%;

        * {
          color: white;
        }
      }
    }
  }
}

.dj-our-brands {
  --bs-gap: 0.75rem;
  --bs-columns: 2;

  @include media-breakpoint-up(sm) {
    --bs-columns: 3;
  }

  @include media-breakpoint-up(md) {
    --bs-columns: 4;
  }

  @include media-breakpoint-up(lg) {
    --bs-columns: 5;
  }
}


.dj-checklist {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  box-shadow: 1px 0 5px rgba(black, .05);
  border-radius: 0.5em;
  border: 1px solid rgba(black, .1);

  &__item {
    display: flex;
    flex-direction: row;
    padding: 1rem;

    &:not(:last-of-type) {
      border-bottom: 1px solid rgba(black, .1);
    }

    > * {
      padding-right: 0.5rem;
      padding-left: 0.5rem;

      @include media-breakpoint-up(md) {
        padding-right: 1rem;
        padding-left: 1rem;
      }

      &:first-child {
        padding-left: 0.25rem;
      }

      &:last-child {
        padding-right: 0.25rem;
      }
    }
  }

  &__field {
    display: flex;
    align-items: center;
    justify-content: start;

    .form-check-input {
      margin: 0;
      width: 1.25em;
      height: 1.25em;

      @include media-breakpoint-up(md) {
        width: 1.5em;
        height: 1.5em;
      }
    }
  }

  &__icon {
    display: flex;
    align-items: center;
    justify-content: center;

    > img,
    > svg {
      max-width: 2.5em;
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  &__content__title {
    font-weight: 500;
  }

  &__content__desc {
  }
}


.StripeElement {
  border: 1px solid rgba(black, .1);
  box-shadow: 1px 0 5px rgba(black, .1);
  border-radius: 0.25rem;
  padding: 0.75em;
}

.dj-form-field-with-icon {
  position: relative;

  .dj-icon {
    position: absolute;
    top: calc(3rem - 0.3em);
    left: 1.5rem;
    color: $gray-500;
  }

  input {
    padding-left: 2rem;
  }
}

.dj-checkout-payment {
  position: relative;
  min-height: 15.625rem;

  .dj-loader {
    position: absolute;
    top: 50%;
    transform: translateY(calc(-1 * (50% + 1.5em)));
  }
}


.dj-grid-table {
  overflow: hidden;
  padding: 1rem;
  border-radius: 0.5em;
  border: 1px solid #e3e9ef;
  box-shadow: 1px 0 5px rgba(black, 0.05);

  &-list {
    &__heading {
      margin-top: 2rem;
      text-align: center;

      @include media-breakpoint-up('md') {
        text-align: left;
      }
    }

    &__item {
      margin-bottom: 1rem;

      &:first-child {
        .dj-grid-table-list__heading {
          margin-top: 0;
        }
      }

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  @include media-breakpoint-up('md') {
    padding: 0;
  }

  &, .grid {
    --bs-gap: 0;
  }

  > .grid:first-child {
    .dj-grid-table__cell {
      border-top: 0;
    }
  }

  @include media-breakpoint-up('md') {
    &__row:not(:first-child) {
      border-top: 1px solid #e3e9ef;
    }
  }

  &__cell {
    //grid-auto-rows: 1fr;
    border: 0 solid #e3e9ef;

    @include media-breakpoint-up('md') {
      border-top-width: 1px;
      border-right-width: 1px;
      border-bottom-width: 0;
      border-left-width: 0;
    }
  }

  &__item {
    padding: 0;

    @include media-breakpoint-up('md') {
      padding: 0.75rem;
    }
  }
}


.dj-stack-table {
  width: 100%;
  border-spacing: 0;

  td, th {
    padding: 0.5rem;
  }

  tr {
    border: 1px solid rgba(gray, 0.2);
  }

  &__item {
    &__image {
      max-width: 60px !important;
    }

    &__inner {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
    }
  }

  @include media-breakpoint-up('md') {
    overflow: hidden;
    border-radius: 0.25rem;
    box-shadow: 1px 0 5px rgba(black, 0.05);

    thead {
      border-top-left-radius: 0.25rem;
      border-top-right-radius: 0.25rem;
    }

    tbody {
      border-bottom-left-radius: 0.25rem;
      border-bottom-right-radius: 0.25rem;
    }

    td {
      border: 1px solid rgba(gray, 0.2);
    }

    &__item {
      &__image {
        padding-right: 1rem;
      }
    }
  }

  @include media-breakpoint-down('md') {
    &, thead, tbody, tr, td, th {
      display: block;
    }

    thead {
      display: none;
      position: absolute;
      left: -9999px;
      overflow: hidden;
      width: 0;
      height: 0;
    }

    tr {
      display: flex;
      flex-direction: column;
      align-items: stretch;
      padding: 0;
      margin-bottom: 2rem;
      overflow: hidden;
      border-radius: 0.25em;
      box-shadow: 1px 0 5px rgba(black, 0.05);
    }

    td, th {
      padding: 0;
      white-space: nowrap;
    }

    &__item {
      &__content {
        padding: 0.25rem 0.75rem;
        word-break: break-all;
        white-space: normal;
      }
    }

    td {
      display: flex;
      flex-direction: row;
      background-color: rgba(gray, 0.02);

      &::before {
        content: attr(data-title);
        align-items: center;
        display: flex;
        padding: 0.5rem 0.75rem;
        width: auto;
        max-width: 100%;
        min-width: 120px;
        font-weight: bold;
        background-color: white;
      }

      &:first-child {
        &::before {
          display: none;
        }
        .dj-stack-table__item__content {
          padding-top: 0.75rem;
          max-width: calc(100% - 150px);
        }

        .dj-stack-table__item__image {
          align-items: center;
          display: flex;
          padding: 0.75rem;
          width: auto;
          max-width: 100%;
          min-width: 120px;
          background-color: white;
        }
      }

      &:last-child {
        .dj-stack-table__item__content {
          padding-bottom: 0.75rem;
        }
      }
    }
  }

  &--return-row-create {
    .dj-form-field {
      margin-bottom: 0 !important;
    }
  }
}

.dj-product:not(.dj-product--detail) {
  .js-action-favorite-button {
    position: absolute;
    top: 0.75rem;
    right: 0.75rem;
  }
}

.dj-product-card {
  &__media,
  &__content {
    position: relative;
  }
}

.has-product-layout-as-card {
  .dj-product {
    @extend .col-lg-3;
    @extend .col-md-4;
    @extend .col-sm-6;

    &-card {
      @extend .flex-column;
    }
  }
}

.has-product-layout-as-list {
  .dj-product {
    @extend .col-lg-4;
    @extend .col-md-6;
    @extend .col-12;

    &-card {
      @extend .flex-row;
      &__media {
        width: auto;
        min-width: 40%;
        max-width: 40%;
      }

      &__content {
        width: 100%;
      }
    }
  }
}

.pagination {
  .page-link {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    padding: 0;
    background-image: linear-gradient(#fff, #f7f6f8);
    border-color: #e3e5e8;
  }

  .page-item {
    &:not(.active) {
      .page-link:hover {
        background-image: linear-gradient(#f7f6f8, #fff);
      }
    }

    &.active {
      .page-link {
        box-shadow: none;
        background-color: transparent;
        border-color: $primary;
        color: $primary;
      }
    }
  }
}


.search-form {
  display: flex;

  @include media-breakpoint-up(md) {
    min-height: 5rem;
  }
}
